import React from "react"
import ImageZoom from "react-medium-image-zoom"

class ZoomImage extends React.Component {
  render() {
    return (
      <ImageZoom
        image={{
          "data-src": this.props.src,
          alt: this.props.alt || this.props.caption,
          className: "img is-radius is-border-shadow is-fade lazyload"
        }}
        zoomImage={{
          "data-src": this.props.zoomsrc || this.props.src,
          alt: this.props.alt || this.props.caption,
          className: "img lazyload"
        }}
      />
    )
  }
}

export default ZoomImage

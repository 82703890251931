import React from "react"
import { Link, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Mikan from "mikanjs"
import { DateTime } from "luxon"

import rehypeReact from "rehype-react"

import Layout from "components/Layout"
import Head from "components/Head"
import GridAside from "components/GridAside"
import TagIcon from "components/TagIcon"
import ZoomImage from "components/ZoomImage"
import LastUpdated from "components/widgets/LastUpdated"
import AllTags from "components/widgets/AllTags"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    img: ZoomImage
  }
}).Compiler

const Ast = ({ ast, ...props }) => {
  ast.properties = props
  return renderAst(ast)
}

export default ({ data }) => (
  <Layout>
    <Head
      id="post"
      pageTitle={data.post.data.title}
      pageDescription={data.post.data.content.childMarkdownRemark.excerpt}
      pageUrl={pjt.site.url + "/posts/" + data.post.data.slug + "/"}
      pageOgImage={
        data.post.data.thumbnails ? data.post.data.thumbnails[0].url : null
      }
    />
    <GridAside>
      <article className="card is-separate is-overflow-hidden is-mobile-fablet-inner-break-top-right-left">
        <header className="card-header">
          <div className="inner is-padding-lg">
            <div className="grid is-middle is-gap-2x">
              <div className="col">
                <TagIcon
                  size="is-xxl"
                  icon_style={data.post.data.tags[0].data.icon_style}
                  icon_name={data.post.data.tags[0].data.icon_name}
                  icon_color={data.post.data.tags[0].data.icon_color}
                />
              </div>
              <div className="col is-mobile-0 is-space-quarter">
                <h1
                  className="heading is-mikan-space-fix is-strong is-dark-2 is-lg"
                  dangerouslySetInnerHTML={{
                    __html: Mikan(data.post.data.title)
                  }}
                />
                <p className="text is-font-en-modern is-dark-3 is-space-right-half is-xs">
                  {(() => {
                    if (data.post.data.status === "open") {
                      return (
                        <span className="badge is-plain is-strong is-open">
                          Open
                        </span>
                      )
                    } else if (data.post.data.status === "closed") {
                      return (
                        <span className="badge is-plain is-strong is-closed">
                          Closed
                        </span>
                      )
                    }
                  })()}
                  <span className="text is-inline-block">
                    <FontAwesomeIcon
                      className="icon"
                      icon={["far", "calendar-alt"]}
                    />
                    <span className="text">first published: </span>
                    <span className="text">
                      {DateTime.fromISO(data.post.data.publishedAt, {
                        zone: "Asia/Tokyo"
                      }).toFormat("yyyy/MM/dd")}
                    </span>
                  </span>
                  <span className="text is-inline-block">
                    <FontAwesomeIcon
                      className="icon"
                      icon={["fas", "history"]}
                    />
                    <span className="text">last updated: </span>
                    <span className="text">
                      {DateTime.fromISO(data.post.data.updatedAt, {
                        zone: "Asia/Tokyo"
                      }).toFormat("yyyy/MM/dd")}
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="thread is-left-autor">
          <Ast
            className="wysiwyg is-external-link-area is-sm"
            ast={data.post.data.content.childMarkdownRemark.htmlAst}
          />
        </div>
        <footer className="card-footer">
          <div className="inner">
            <div className="btns is-center is-xs">
              {data.post.data.tags.map(({ data }, index) =>
                data.published === true ? (
                  <Link
                    to={"/tags/" + data.slug + "/"}
                    className="btn is-outline"
                    key={index}
                  >
                    <span className="icon is-dark-4">
                      <FontAwesomeIcon icon={["fas", "tag"]} />
                    </span>
                    <span className="text">{data.name}</span>
                  </Link>
                ) : null
              )}
            </div>
          </div>
        </footer>
      </article>
      <LastUpdated />
      <AllTags />
    </GridAside>
  </Layout>
)

export const query = graphql`
  query GetPage($slug: String!) {
    post: airtable(table: { eq: "posts" }, data: { slug: { eq: $slug } }) {
      data {
        title
        published
        slug
        publishedAt
        updatedAt
        status
        tags {
          data {
            name
            published
            slug
            icon_style
            icon_name
            icon_color
          }
        }
        thumbnails {
          url
        }
        content {
          childMarkdownRemark {
            htmlAst
            excerpt(truncate: true)
          }
        }
      }
    }
  }
`
